import styled from 'styled-components';
import { Text } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';

export const TimelineWrapper = styled.div`
  position: relative;
  right: 50%;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  display: flex;
  overflow: auto hidden;
  flex-wrap: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  cursor: pointer;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    cursor: grabbing;
    transform: scale(1.03);
  }

  @media (max-width: ${breakpoints.m}) {
    scroll-snap-type: x mandatory;
    scroll-padding-left: ${spacing.space24};
  }
`;

export const TimelineTitle = styled.div`
  @media (max-width: ${breakpoints.s}) {
    height: ${spacing.space80};

    h2,
    p {
      margin-top: 20px;
    }
  }
`;

export const IngressText = styled(Text)`
  margin-bottom: ${spacing.space32};
  font-size: 1.45rem;
`;

export const SwipeInformation = styled.div`
  text-align: right;
  opacity: 0;
  visibility: hidden;
  padding: 0 ${spacing.space24};

  p {
    line-height: 2.25rem;
    font-style: italic;
    display: inline-block;
    margin-right: ${spacing.space20};
  }

  @media (max-width: ${breakpoints.m}) {
    opacity: 1;
    visibility: visible;
    transition: visibility 0s 1s, opacity 1s linear;

    &.scrolling {
      opacity: 0;
      visibility: visible;
      transition: visibility 0s 1s, opacity 1s linear;
    }
  }
`;

export const TimelineGraph = styled.div<{ $hasImage?: boolean }>`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => (theme.typography ? theme.typography.defaultText : themeLight.typography.defaultText)};
  position: relative;

  &::before {
    content: ' ';
    background-color: ${({ theme }) =>
      theme.componentColors ? theme.componentColors.extras.accent : themeLight.componentColors.extras.accent};
    ${(props) => (props.$hasImage ? 'border-radius: 0;' : 'border-radius: 50%;')}
    width: 16px;
    height: 16px;
    top: -7px;
    left: 0;
    position: absolute;
  }
`;
