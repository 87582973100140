import React, { useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import { AdvancedImage } from '@cloudinary/react';
import { limitFill } from '@cloudinary/url-gen/actions/resize';
import { useCloudinary } from '../../../../../hooks/Cloudinary/useCloudinary';
import { TimelineImageType } from '../../../../../../../types/timelineType';
import { TimelineGraph, TimelineTitle } from '../Styles';
import * as S from './Styles';

const TimelineImageItem = ({ item: { title, image } }: { item: TimelineImageType }) => {
  const cld = useCloudinary();
  const [timelineImage] = useState(
    (image && cld.image(image.public_id).resize(limitFill().width(320)).quality('auto:best').format('auto')) ||
      undefined,
  );

  return (
    <S.TimelineImage>
      <TimelineTitle>{title && <Text variant={TextVariant.ArticleTextLarge}>{title}</Text>}</TimelineTitle>
      <TimelineGraph $hasImage />
      {timelineImage && <AdvancedImage alt={image?.alt || image?.altText} cldImg={timelineImage} plugins={[]} />}
    </S.TimelineImage>
  );
};

export default TimelineImageItem;
